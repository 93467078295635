<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: null,

      plans: null,
      plan: null,
      monthlies: {
        loading: true,
        errored: false,
        total: 0,
        fields: [
          { key: "id", label: "#", sortable: true },
          { key: "start", label: "Data", sortable: true },
          { key: "plan", label: "Plano", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "order", label: "", tdClass: 'text-right' },
        ],
        items: null,

        sortBy: "name",
        sortDesc: false,
        currentPage: "1",
        perPage: "25",
      },

      buy: {
        modal: false,
        loading: false,
        id: '',
        name: '',
        description: '',
        value: '',
      }
    };
  },
  methods: {
    getPlans() {
      this.plans = null;

      api
        .get('plans')
        .then((response) => {
          if (response.data.status == 'success') {
            this.plans = response.data.list;
          }
        })
    },
    getPlan() {
      this.plan = null;
      this.statData = null;

      api
        .get('plans/current')
        .then((response) => {
          if (response.data.status == 'success') {
            this.plan = response.data.plan

            if (this.plan.status == 'active') {
              this.statData = [
                {
                  title: "Plano Atual",
                  value: this.plan.name,
                },
                {
                  title: "Status",
                  value: this.plan.status,
                },
              ]
            } else {
              this.statData = [
                {
                  title: "Plano Atual",
                  value: this.plan.name,
                },
                {
                  title: "Status",
                  value: this.plan.status,
                },
              ]
            }
          } else {
            this.statData = [
              {
                title: "Plano Atual",
                value: "Divulgador",
              },
            ];
          }
        })
    },
    getMonthlies() {
      this.monthlies.total = 0;
      this.monthlies.items = null;

      api
        .get('plans/monthlies')
        .then((response) => {
          if (response.data.status == 'success') {
            this.monthlies.total = response.data.list.length;
            this.monthlies.items = response.data.list;
          }
        })
    },
    showBuy(plan) {
      this.buy.modal = false;
      this.buy.modal = true;
      this.buy.id = plan.id;
      this.buy.name = plan.name;
      this.buy.description = plan.description;
      this.buy.value = plan.value;
    },
    buyPlan() {
      if (this.buy.id) {
        this.buy.loading = true;

        api
          .post("plans", {
            id: this.buy.id
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$router.push('/plans/order/' + response.data.order.id);
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.buy.modal = false;
              this.buy.loading = false;
            }
          })
          .finally(() => {
            this.buy.modal = false;
            this.buy.loading = false;
          });
      }
    },
    changePlan() {
      this.plan = null;
      this.monthlies.total = 0;
      this.monthlies.items = null;
    },
    renewPlan() {
    },
  },
  mounted() {
    this.getPlans()
    this.getPlan()
    this.getMonthlies()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Meu Plano') }}</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3 col-lg-3 col-xl-3">
        <Stat :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="!plan && !plans" class="text-center">
      <b-spinner class="ml-2 align-middle" variant="default" role="status"></b-spinner>
    </div>
    <div v-else-if="!plan && plans && monthlies && monthlies.total == 0">
      <h5 class="mb-5">Escolha a melhor opção para você</h5>
      <div class="row">
        <div class="col-lg-4" v-for="(plan, index) in plans" :key="index">
          <div class="card card-h-fix">
            <div class="card-body">
              <div class="plan-title d-flex justify-content-end align-items-center text-uppercase" :class="'plan-' + plan.slug">
                <img class="plan-image" :src="require('@/assets/images/plans/' + plan.image)" />
                <h3 class="ml-2 mb-0 font-size-26 text-white">{{ plan.name }}</h3>
              </div>
              <h2 class="mb-3 text-dark text-center">{{ plan.value | currency }}</h2>
              <div class="mb-2 pb-2 border-bottom">
                Rendimento Fixo<br>
                <h5>
                  {{ plan.profit.value | currency }}<br>
                  <small>*{{ plan.profit.month }} meses</small>
                </h5>
              </div>
              <div class="mb-2 pb-2 border-bottom">
                Bônus de Indicação: 6%
              </div>
              <div class="mb-2 pb-2 border-bottom">
                Bônus de Residual: 2% (até o 6º nível)
              </div>
              <div class="mb-2 pb-2 border-bottom">
                Bônus de Equiparação: {{ plan.binary }}
              </div>
              <div class="mb-3" v-html="plan.description">
              </div>
              <button class="btn btn-default btn-block text-uppercase" v-on:click="showBuy(plan)">
                Escolher
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="buy.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Confirmar compra</h4>
        <p>Confirma a compra do plano <strong>{{ buy.name }}</strong>?</p>
        <div class="d-flex justify-content-between">
          <button class="btn btn-default mr-3" v-on:click="buyPlan()" :disabled="this.buy.loading">
            Confirmar
            <b-spinner v-if="buy.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <button class="btn btn-outline-link" v-on:click="buy.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>

    <div v-if="monthlies && monthlies.total > 0" id="dropdown" class="mb-4">
      <b-dropdown class="bg-white" variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item v-on:click="changePlan()">Alterar plano</b-dropdown-item>
        <b-dropdown-item v-on:click="renewPlan()" disabled>Renovar plano</b-dropdown-item>
      </b-dropdown>
    </div>

    <div v-if="monthlies && monthlies.total > 0" class="card">
      <div class="card-header rounded-top">
        <h4 class="card-title mb-0">Minhas Faturas</h4>
      </div>
      <div class="card-body">
        <div class="error text-center min-vh-25" v-if="monthlies.errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <div v-else-if="monthlies.items && monthlies.items.length == 0" class="empty text-center min-vh-25">
          <strong>Nenhum registro encontrado.</strong>
        </div>
        <div v-else class="table-responsive">
          <b-table :items="monthlies.items" :fields="monthlies.fields" class="table-nowrap table-style table-hover min-vh-25" head-variant="light" :sort-by.sync="monthlies.sortBy"
            :sort-desc.sync="monthlies.sortDesc" :current-page="monthlies.currentPage" :per-page="monthlies.perPage" responsive>
            <template #cell(start)="row">
              {{ row.item.date.start }}
            </template>
            <template #cell(end)="row">
              {{ row.item.date.end }}
            </template>
            <template #cell(plan)="row">
              {{ row.item.plan.name }}
            </template>
            <template #cell(status)="row">
              <span v-if="row.item.status === 'pending'" class="p-3 px-4 badge badge-soft-warning font-size-14">{{ t("Aguardando pagamento") }}</span>
              <span v-else-if="row.item.status === 'approved'" class="p-3 px-4 badge badge-soft-success font-size-14">{{ t("Aprovado") }}</span>
              <span v-else-if="row.item.status === 'canceled'" class="p-3 px-4 badge badge-soft-danger font-size-14">{{ t("Cancelado") }}</span>
            </template>
            <template #cell(order)="row">
              <router-link v-if="row.item.order.id > 0" tag="a" :to="'/plans/order/' + row.item.order.id" class="btn btn-default">
                <i class="bx bx-show font-size-18 align-middle mr-1"></i>
                Ver
              </router-link>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.plan-title {
  position: relative;
  border-radius: 10px;
  margin: 10px 0 30px;
  padding: 15px 20px;
}

.plan-basic {
  background: rgb(201, 2, 0);
  background: linear-gradient(180deg, rgba(201, 2, 0, 1) 0%, rgba(121, 4, 3, 1) 100%);
}

.plan-business {
  background: rgb(55, 128, 38);
  background: linear-gradient(180deg, rgba(55, 128, 38, 1) 0%, rgba(32, 71, 26, 1) 100%);
}

.plan-premium {
  background: rgb(3, 77, 108);
  background: linear-gradient(180deg, rgba(3, 77, 108, 1) 0%, rgba(5, 45, 64, 1) 100%);
}

.plan-image {
  position: absolute;
  top: -20px;
  left: 20px;
  width: 90px;
}
</style>